<template>
  <button
    v-if="siteHasFeature('Accounts')"
    class="bookmark-button text-button"
    @click="tryToBookmark"
  >
    <icon-component
      name="ribbon"
      title="Bookmark for later"
      class="bookmark-button__icon"
      :class="{ 'bookmark-button__icon--filled' : isBookmarked }"
    />
    <span v-if="showText">
      {{ buttonText }}
    </span>
  </button>
</template>

<script>
import { mapGetters } from 'vuex';
import { UPDATE_BOOKMARK } from '@/graphql/mutations/bookmark-mutations';

export default {
  name: 'BookmarkButton',
  props: {
    bookmarkable: {
      type: Object,
      required: true,
    },
    showText: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters([
      'currentUser',
      'siteHasFeature',
      'userIsLoggedIn',
    ]),
    isBookmarked() {
      if (this.userIsLoggedIn && this.currentUser.bookmarks.length) {
        const matchingBookmarks = this.currentUser.bookmarks.filter(
          (bookmark) => bookmark.assocType === this.bookmarkPayload.assocType
          && bookmark.assocId === this.bookmarkPayload.assocId,
        );
        return matchingBookmarks.length > 0;
      }
      return false;
    },
    bookmarkPayload() {
      return {
        assocType: this.bookmarkableType.toUpperCase(),
        assocId: this.bookmarkable.id,
      };
    },
    bookmarkableType() {
      if (this.bookmarkable.type) {
        return this.bookmarkable.type.toUpperCase();
      }
      return 'THREAD';
    },
    buttonText() {
      return this.isBookmarked ? 'Saved' : 'Save';
    },
  },
  methods: {
    tryToBookmark(event) {
      if (!this.userIsLoggedIn) {
        this.$store.dispatch('openRegisterDialog', { dialogText: 'Create an account to bookmark this article.' });
        return;
      }

      this.$snowplow.trackButtonEvent({
        data: {
          type: `${this.isBookmarked ? 'unbookmark' : 'bookmark'}`,
          text: event.target.textContent.trim(),
          classes: event.target.classList,
          id: event.target.id,
        },
      });
      this.handleBookmarking();
    },
    handleBookmarking() {
      this.$apollo.mutate({
        mutation: UPDATE_BOOKMARK,
        variables: {
          assocId: this.bookmarkable.id,
          assocType: this.bookmarkableType,
          isDelete: this.isBookmarked,
        },
        update(cache) {
          // When a user bookmarks or unbookmarks there can be many associated
          // cached queries that would need to be updated. Rather than deal with
          // all the crazy logic for that, just delete any relevant cache entries
          // and let the network handle subsequent queries
          const bookmarkCacheKeys = Object.keys(cache.data.data.ROOT_QUERY).filter((key) => /^bookmarkList/.test(key));

          if (!bookmarkCacheKeys.length) { return; }

          bookmarkCacheKeys.forEach((key) => {
            // eslint-disable-next-line no-param-reassign
            delete cache.data.data.ROOT_QUERY[key];
          });
        },
      }).then(() => {
        if (this.isBookmarked) {
          this.$store.dispatch('removeBookmarkFromCurrentUser', this.bookmarkPayload);
          this.$store.dispatch('addToastNotification', {
            toastType: 'success',
            description: 'Your bookmark has been removed.',
            type: 'Bookmarking',
          });
        } else {
          this.$store.dispatch('addBookmarkToCurrentUser', this.bookmarkPayload);
          this.$store.dispatch('addToastNotification', {
            toastType: 'success',
            description: 'Bookmarked!',
            type: 'Bookmarking',
          });
        }
      }).catch(() => {
        this.$store.dispatch('addGenericErrorNotification');
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_bookmark-button';
</style>
